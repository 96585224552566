<template>
    <div class="scope-container">
        <div class="scope-filters">
            <slot name="scope-select"></slot>
            <v-text-field
                v-model="search"
                class="search"
                :placeholder="t('messages.resources.searchCompany', 'Søk bedrift')"
                append-inner-icon="mdi-magnify"
                color="primary"
                variant="outlined"
            ></v-text-field>
        </div>
        <div class="scope-content">
            <v-data-table
                v-model="selectedTableRow"
                :sort-by="sortBy"
                :headers="headers"
                :items="tableRows"
                :row-props="rowProps"
                :search="search"
                select-strategy="single"
                items-per-page="-1"
                no-data-text=""
                return-object
                show-select
                fixed-header
                height="calc(100vh - 255px)"
                @click:row="updateSelectedCompany"
            >
                <template #bottom>
                    <div class="companies-total">{{ `${t('messages.resources.total', 'Total')}: ${tableRows.length}` }}</div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { Company } from '@/Features/Companies/companiesModels';
import { computed, ref, watch } from 'vue';
import { Receiver } from '../../messagesModels';
import { companyReceiversEqual } from '../../helpers';

const { t } = useI18n();

const model = defineModel<Receiver | null>();

interface Props {
    companies: Company[];
}

interface TableRow {
    companyName: string;
    companyNumber: string;
    data: Receiver;
}

const sortBy = ref<any[]>([{ key: 'companyName', order: 'asc' }]);
const headers = computed<any[]>(() => [
    {
        title: t('messages.resources.scopeCompanyName', 'Navn'),
        key: 'companyName',
        sortable: true
    },
    {
        title: t('messages.resources.scopeCompanyNumber', 'Nummer'),
        key: 'companyNumber',
        sortable: true
    }
]);
const search = ref('');

const props = defineProps<Props>();

const tableRows = ref<TableRow[]>([]);
const selectedTableRow = ref<TableRow[]>([]);

watch(model, () => {
    if (!model.value) selectedTableRow.value = [];
});

loadTableRows(props.companies);

function loadTableRows(companies: Company[]) {
    tableRows.value = companies.map(x => toCompanyReceiverTableRow(x));
}

function toCompanyReceiverTableRow(company: Company): TableRow {
    const companyReceiver = {
        id: company.companyNumber,
        name: company.name
    };
    return {
        companyName: company.name,
        companyNumber: company.companyNumber,
        data: companyReceiver
    };
}

function updateSelectedCompany(_, row: any) {
    const companyToRemove = selectedTableRow.value[0] ? companyReceiversEqual(selectedTableRow.value[0].data, row.item.data) : false;
    if (companyToRemove) {
        selectedTableRow.value = [];
        model.value = null;
    } else {
        selectedTableRow.value = [row.item];
        model.value = row.item.data;
    }
}

function rowProps(row: any) {
    const isSelected = selectedTableRow.value[0]?.data.id === row.item.data.id;
    if (isSelected) {
        return { class: 'selected' };
    }
    return {};
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.scope-filters {
    display: flex;
    column-gap: 20px;

    .search {
        max-width: 350px;
    }
}

.scope-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.v-table {
    :deep(.selected) {
        background-color: $selectedGreen;

        &:hover {
            background-color: $lightGreen;
        }

        .v-icon {
            color: $primary;
        }
    }
}

.companies-total {
    color: #a0a0a0;
    font-size: 14px;
    padding: 12px 0 0 12px;
}

@media screen and (max-width: 1038px) {
    :deep(.v-table__wrapper) {
        max-height: calc(100vh - 400px) !important;
    }
}
</style>
