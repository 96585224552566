<template>
    <div class="date-time-edit">
        <div>
            <v-label>{{ t('messages.resources.from', 'Fra') }}</v-label>
            <div class="date-from">
                <div>
                    <v-menu
                        v-model="startPublish.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                    >
                        <template #activator="{ props: menuProps }">
                            <v-text-field
                                v-model="startPublish.date"
                                data-id="DateTextField"
                                readonly
                                v-bind="menuProps"
                                variant="outlined"
                                append-inner-icon="mdi-calendar"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startPublishPicker"
                            data-id="DatePicker"
                            show-adjacent-months
                            @update:model-value="updateStartPublishTime"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div>
                    <v-text-field
                        v-model="startPublish.time"
                        type="time"
                        data-id="TimeTextField"
                        variant="outlined"
                        @update:model-value="updateStartPublishTime"
                    ></v-text-field>
                </div>
            </div>
            <v-label>{{ t('messages.resources.to', 'Til') }}</v-label>
            <div class="date-to">
                <div>
                    <v-menu
                        v-model="endPublish.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="290px"
                        offset-y
                        transition="scale-transition"
                    >
                        <template #activator="{ props: menuProps }">
                            <v-text-field
                                v-model="endPublish.date"
                                data-id="DateTextField"
                                readonly
                                v-bind="menuProps"
                                variant="outlined"
                                append-inner-icon="mdi-calendar"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endPublishPicker"
                            data-id="DatePicker"
                            show-adjacent-months
                            @update:model-value="updateEndPublishTime"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div>
                    <v-text-field
                        v-model="endPublish.time"
                        type="time"
                        data-id="TimeTextField"
                        variant="outlined"
                        @update:model-value="updateEndPublishTime"
                    ></v-text-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { ToLocalDate, FormatDateISO, DateToUTC } from '@/shared/DateFormatHelpers';

const { t } = useI18n();

interface Props {
    startDate?: Date;
    endDate?: Date;
}
const props = defineProps<Props>();

const emit = defineEmits<{
    (e: 'updateStartPublishTime', dateTime: Date);
    (e: 'updateEndPublishTime', dateTime: Date);
}>();

const startPublish = ref({
    menu: false,
    menuTime: false,
    date: props.startDate ? ToLocalDate(props.startDate, 'ISOdate') : '',
    time: props.startDate ? ToLocalDate(props.startDate, 'time') : ''
});

const startPublishPicker = computed<Date>({
    get: () => {
        return new Date(startPublish.value.date);
    },
    set: (value: Date) => {
        const d = value;
        startPublish.value.date = FormatDateISO(d);
    }
});

const endPublish = ref({
    menu: false,
    menuTime: false,
    date: props.endDate ? ToLocalDate(props.endDate, 'ISOdate') : '',
    time: props.endDate ? ToLocalDate(props.endDate, 'time') : ''
});

const endPublishPicker = computed<Date>({
    get: () => {
        return new Date(endPublish.value.date);
    },
    set: (value: Date) => {
        const d = value;
        endPublish.value.date = FormatDateISO(d);
    }
});

function updateStartPublishTime() {
    const newDateTime = DateToUTC(startPublish.value.date, startPublish.value.time);
    emit('updateStartPublishTime', newDateTime);
}

function updateEndPublishTime() {
    const newDateTime = DateToUTC(endPublish.value.date, endPublish.value.time);
    emit('updateEndPublishTime', newDateTime);
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.date-from,
.date-to {
    display: grid;
    grid-template-columns: calc(65% - 12px) calc(35% - 12px);
    column-gap: 24px;
}

.date-time-edit {
    :deep(input) {
        padding: 4px 16px;
        min-height: 40px;
    }

    :deep(.v-field) {
        background-color: $white;
    }

    :deep(.v-text-field .v-input__details) {
        display: none;
    }

    .v-label {
        font-size: 12px;
        margin-bottom: 8px;
        color: $black;
    }

    .date-from {
        margin-bottom: 12px;
    }
}
</style>
