import { Link } from '@/shared/models';

export interface Message {
    id?: string;
    groupId?: string;
    subject: string;
    startPublish?: Date;
    endPublish?: Date;
    body?: string;
    subjectEn?: string;
    bodyEn?: string;
    pushNotifications: boolean | null;
    notificationType?: MessageNotificationType;
    status: MessageStatus;
    createdAt?: string;
    channels: Array<string>;
    receivers: Receivers;
    structuredMessage: {
        templateId: string;
        input: {
            [key: string]: string;
        };
        choice: {
            [key: string]: string;
        };
        comment?: string;
    };
    hasChangedSincePublish: boolean;
    author?: string;
    sourceSystem?: string;
    auditEntries: Array<MessageAudit>;
    shouldPublish: boolean;
}

export interface MessageAudit {
    id: string;
    messageId: string;
    companyId: string;
    messageAuthor: MessageAuthor;
    operation: AuditOperation;
    createdAt: string;
}

export interface MessageAuthor {
    username: string;
    firstName: string;
    lastName: string;
}

export enum AuditOperation {
    None = 'None',
    Create = 'Create',
    Edit = 'Edit',
    Delete = 'Delete',
    Publish = 'Publish',
    RePublish = 'RePublish',
    Unpublish = 'Unpublish'
}

export interface MessageListItem {
    id: string;
    groupId: string;
    subject: string;
    startPublish: Date;
    endPublish: Date;
    sentTo?: string;
    status: MessageStatus;
    channels?: Array<string>;
    departureTime?: string;
    destination?: string;
    departure?: string;
    lineName?: string;
    reason: string;
    line?: string;
    driverId?: string;
    notificationType: MessageNotificationType;
    author?: string;
    createdAt: string;
    templateId?: string;
    links: Link[];
}

export interface Receiver {
    id: string;
    name?: string;
}

export interface StopReceiver extends Receiver {
    fullName: string;
    publicCode: string;
}

export interface DriverReceiver extends Receiver {
    companyId: string;
}

export interface DepartureReceiver extends Receiver {
    departureTime: string;
    operatingDay: string;
    departure: string;
    destination: string;
    lineName: string;
    companyNumber: string;
}

export interface LineReceiver extends Receiver {
    transportType: string;
}

export interface Receivers {
    scopes: Array<string>;
    lines: Array<LineReceiver>;
    stops: Array<StopReceiver>;
    areas: Array<Receiver>;
    companies: Array<Receiver>;
    drivers: Array<DriverReceiver>;
    departures: Array<DepartureReceiver>;
}

export const Scopes = {
    Areas: 'Areas',
    Departures: 'Departures',
    Drivers: 'Drivers',
    Lines: 'Lines',
    Companies: 'Companies',
    Stops: 'Stops'
};

export enum MessageStatus {
    Created = 'Created',
    Published = 'Published',
    Unpublished = 'Unpublished'
}

export enum MessageNotificationType {
    Information = 'Information',
    Warning = 'Warning',
    Cancellation = 'Cancellation',
    EarlyTripEnd = 'EarlyTripEnd',
    EquipmentFailure = 'EquipmentFailure',
    Full = 'Full',
    OnboardTicketMachine = 'OnboardTicketMachine',
    AtStopOther = 'AtStopOther'
}

export interface MessageTemplate {
    id: string;
    name: string;
    label: string;
    categoryName: string;
    categoryLabel: string;
    messageScopes: MessageScope[];
    editable: boolean;
    pushAvailable: boolean;
    transportTypes: TransportType[];
    notificationType: MessageNotificationType;
    inputTemplates: MessageInputTemplate[];
    choiceTemplates: MessageChoiceTemplate[];
    isFirstLevel: boolean;
}

export interface MessageInputTemplate {
    name: string;
    label: string;
    inputType: MessageInputType;
}

export interface MessageChoiceTemplate {
    name: string;
    label: string;
    group: string;
    groupLabel: string;
}

export interface MessageCategoryTemplate {
    id: string;
    name: string;
    label: string;
}

export const CancellationCategoryName = 'Cancellation';

export interface ServiceJourney {
    tripId: string;
    linePrivateCode: string;
    lineName: string;
    departure: string;
    destination: string;
    departureAt: string;
    arrivalAt: string;
    operatingDay: string;
    companyNumber: string;
    status: TripStatus;
}

export enum MessageInputType {
    ShortText,
    Number
}

export enum MessageScope {
    Global = 'Global',
    Lines = 'Lines',
    Departures = 'Departures',
    Stops = 'Stops',
    Areas = 'Areas',
    Companies = 'Companies',
    Drivers = 'Drivers'
}

export enum TransportType {
    None = 0,
    Bus = 1,
    Water = 2,
    Rail = 3,
    Bike = 4,
    Unknown = 5
}

export type DepartureGroup = {
    operatingDay: string;
    variants: DepartureVariant[];
};

export type DepartureVariant = {
    departure: string;
    destination: string;
    lineName: string;
    companyNumber: string;
    departures: DepartureReceiver[];
};

export enum TripStatus {
    Unknown = 'Unknown',
    Cancelled = 'Cancelled'
}
