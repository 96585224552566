<template>
    <div class="summary-container">
        <div v-if="lines.length > 0" class="lines-summary">
            <div class="lines-title">
                {{ t('messages.resources.lineScopeSummaryTitle', 'Valgt linje') }}
            </div>
            <div class="selected-lines">
                <div v-for="line in lines" :key="line.id" class="line" @click="emit('removeLine', line)">
                    {{ line.name }}
                </div>
            </div>
        </div>
        <div v-else class="summary-nodata">
            {{ t('messages.resources.linesScopeSummaryNoLines', 'Velg en linje i tabellen.') }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { LineReceiver } from '../../messagesModels';

const { t } = useI18n();

interface Props {
    lines: LineReceiver[];
}
const emit = defineEmits<{
    (e: 'removeLine', line: LineReceiver);
}>();

defineProps<Props>();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.lines-title {
    font-size: 16px;
    font-weight: bold;
    padding: 32px 0 16px;
    display: flex;
    justify-content: space-between;
}

.selected-lines {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    gap: 8px;
    flex-wrap: wrap;

    .line {
        font-weight: bold;
        color: $lightGrey;
        background-color: $black;
        min-width: 30px;
        padding: 0 3px;
        height: 24px;
        padding-top: 1px;
        vertical-align: middle;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
    }
}

.summary-nodata {
    font-size: 14px;
}
</style>
