<template>
    <div class="summary-container">
        <div v-if="stops.length > 0" class="stops-summary">
            <div class="stops-title">
                {{ t('messages.resources.stopsScopeSummaryTitle', 'Valgt stopp') }}
            </div>
            <div class="selected-stops">
                <div v-for="stop in stops" :key="stop.id" class="stop" @click="emit('removeStop', stop)">
                    <img src="@/assets/busstop.svg" alt="Bus stop" />
                    <span>{{ stop.name }}</span>
                    <div v-if="stop.publicCode" class="platform">
                        <span>{{ stop.publicCode }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="summary-nodata">
            {{ t('messages.resources.stopsScopeSummaryNoStops', 'Velg et stopp i tabellen.') }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { StopReceiver } from '../../messagesModels';

const { t } = useI18n();

interface Props {
    stops: StopReceiver[];
}
const emit = defineEmits<{
    (e: 'removeStop', line: StopReceiver);
}>();

defineProps<Props>();
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.stops-title {
    font-size: 16px;
    font-weight: bold;
    padding: 32px 0 16px;
    display: flex;
    justify-content: space-between;
}

.selected-stops {
    .stop {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        img {
            height: 20px;
        }
        span {
            font-size: 14px;
            font-weight: bold;
        }

        .platform {
            height: 19px;
            width: 19px;
            background-image: url(@/assets/platform.svg);
            background-size: contain;
            position: relative;

            span {
                position: absolute;
                top: 1px;
                left: -1px;
                width: 100%;
                text-align: center;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }
}

.summary-nodata {
    font-size: 14px;
}
</style>
