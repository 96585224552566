<template>
    <div v-if="loading"><v-skeleton-loader type="card" class="loader"></v-skeleton-loader></div>
    <div v-else class="list-item-summary">
        <div class="title-row">
            <div class="title">{{ message.subject }}</div>
            <v-icon icon="mdi-close" @click="emit('closeSummary')"></v-icon>
        </div>
        <div v-if="messageTemplate" class="subtitle">{{ messageTemplate.label }}</div>
        <div v-if="scope === MessageScopes.Global" class="global-scope">
            <div class="global-scope-title">{{ t('messages.resources.global', 'Global') }}</div>
        </div>
        <departures-summary v-if="isDeparture" :departures="departures" :notification-type="message.notificationType" />
        <lines-summary v-if="scope === MessageScopes.Lines" :lines="message.receivers.lines" />
        <operators-summary v-if="scope === MessageScopes.Companies" :operators="message.receivers.companies" />
        <stops-summary v-if="scope === MessageScopes.Stops" :stops="message.receivers.stops" />
        <drivers-summary v-if="scope === MessageScopes.Drivers" :drivers="message.receivers.drivers" />
        <div v-if="messageTemplate?.editable" class="text-message">
            <div v-if="isCancellation">
                <span class="editor-label">{{ t('messages.resources.reasonForCancellation', 'Årsak til innstilling') }}</span>
            </div>
            <div v-else>
                <span class="editor-label">{{ t('messages.resources.message', 'Melding') }}</span>
            </div>
            <editor
                v-model="messageText"
                :init="{
                    entity_encoding: 'raw',
                    height: 150,
                    menubar: false,
                    language: 'nb_NO',
                    language_url: 'https://cdn.jsdelivr.net/npm/tinymce-lang/langs/nb_NO.js',
                    plugins: ['lists link'],
                    toolbar: 'bold italic | link | help',
                    content_css: '/tinymce.css'
                }"
                data-id="BodyEditor"
            />
        </div>
        <div class="publishing">
            <div class="title">{{ t('messages.resources.publishingTitle', 'Gjeldende') }}</div>
            <div v-if="isDeparture" class="publishing-dates">
                <div class="from">
                    <span>{{ `${t('messages.resources.from', 'Fra')}: ` }}</span>
                    <span>{{ `${ToLocalDate(message.startPublish, 'date')} ${ToLocalDate(message.startPublish, 'time')}` }}</span>
                </div>
                <div class="to">
                    <span>{{ `${t('messages.resources.to', 'Til')}: ` }}</span>
                    <span>{{ `${ToLocalDate(message.endPublish, 'date')} ${ToLocalDate(message.endPublish, 'time')}` }}</span>
                </div>
            </div>
            <div v-else>
                <summary-date-edit
                    :start-date="message.startPublish"
                    :end-date="message.endPublish"
                    @update-start-publish-time="updateStartPublish"
                    @update-end-publish-time="updateEndPublish"
                />
            </div>
            <div v-if="loadingUpdate">
                <v-skeleton-loader class="loading-edit" type="actions"></v-skeleton-loader>
            </div>
            <div v-else class="message-action" :class="{ 'single-button': !canUpdateMessage }">
                <v-dialog v-model="dialog" max-width="500">
                    <template #activator="{ props: activatorProps }">
                        <v-btn :class="{ published: isPublished }" variant="outlined" v-bind="activatorProps">{{
                            publishingActionText
                        }}</v-btn>
                    </template>
                    <div class="confirmation-dialog">
                        <h2 class="title">{{ dialogTitle }}</h2>
                        <div class="text">{{ dialogText }}</div>

                        <div class="actions">
                            <v-btn color="primary" variant="outlined" @click="dialog = false">
                                {{ t('shared.resources.common.cancel', 'Avbryt') }}
                            </v-btn>
                            <v-btn data-id="PublishButton" class="action" :class="{ published: isPublished }" @click="publishMessage">
                                {{ publishingActionText }}
                            </v-btn>
                        </div>
                    </div>
                </v-dialog>
                <v-btn v-if="canUpdateMessage" data-id="EditButton" class="action edit-message" variant="outlined" @click="updateMessage">
                    {{ t('messages.resources.updateMessage', 'Oppdater melding') }}
                </v-btn>
            </div>
        </div>
        <audit-log :audit-entries="message.auditEntries" />
    </div>
</template>

<script setup lang="ts">
import SummaryDateEdit from './SummaryDateEdit.vue';
import AuditLog from './AuditLog.vue';
import { Message, MessageStatus, MessageTemplate, MessageScope, CancellationCategoryName } from '../messagesModels';
import { useI18n } from 'vue-i18n';
import { ToLocalDate } from '@/shared/DateFormatHelpers';
import { computed, ref } from 'vue';
import DeparturesSummary from './Scopes/DeparturesSummary.vue';
import LinesSummary from './Scopes/LinesSummary.vue';
import OperatorsSummary from './Scopes/OperatorsSummary.vue';
import StopsSummary from './Scopes/StopsSummary.vue';
import DriversSummary from './Scopes/DriversSummary.vue';
import Editor from '@tinymce/tinymce-vue';
import { useVModel } from '@vueuse/core';

interface Props {
    modelValue: Message;
    messageTemplate?: MessageTemplate;
    loading: boolean;
    loadingUpdate: boolean;
}
interface Emits {
    (e: 'update:modelValue', modelValue: Message): void;
    (e: 'closeSummary'): void;
    (e: 'unpublish'): void;
    (e: 'publish'): void;
    (e: 'updateMessage'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const message = useVModel(props, 'modelValue', emit);

const { t } = useI18n();
const MessageScopes = MessageScope;
const scope = computed(() => message.value.receivers.scopes[0]);
const isDeparture = computed(() => scope.value === MessageScope.Departures);
const canUpdateMessage = computed(() => props.messageTemplate?.editable || !isDeparture.value);
const isCancellation = computed(() => props.messageTemplate?.categoryName === CancellationCategoryName);
const departures = computed(() => message.value.receivers.departures);
const isPublished = computed(() => message.value.status === MessageStatus.Published);
const publishingActionText = computed(() =>
    isPublished.value ? t('messages.resources.unpublish', 'Avpubliser') : t('messages.resources.publish', 'Publisere')
);
const dialog = ref(false);
const dialogTitle = computed(() =>
    isPublished.value ? t('messages.resources.dialogTitleUnpublish') : t('messages.resources.dialogTitlePublish')
);
const dialogText = computed(() =>
    isPublished.value ? t('messages.resources.dialogTextUnpublish') : t('messages.resources.dialogTextPublish')
);
const messageText = computed<string>({
    get: () => {
        return message.value.body || '';
    },
    set: (val: string) => {
        message.value.body = val;
    }
});

function updateStartPublish(val: Date) {
    message.value.startPublish = val;
}

function updateEndPublish(val: Date) {
    message.value.endPublish = val;
}

function updateMessage() {
    emit('updateMessage');
}

function publishMessage() {
    if (isPublished.value) {
        emit('unpublish');
    } else {
        emit('publish');
    }
    dialog.value = false;
}
</script>

<style lang="scss" scoped>
@import '@/shared/variables.scss';

.list-item-summary {
    background-color: $lightGrey;
    padding: 16px 21px;
    margin: 12px 0 12px 12px;
    border-radius: 8px;

    .title-row {
        display: flex;
        justify-content: space-between;

        .title {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .global-scope-title {
        font-size: 16px;
        font-weight: bold;
        padding-top: 32px;
    }

    .text-message {
        margin-top: 32px;
    }

    .editor-label {
        font-size: 14px;
        color: $iconGrey;
    }

    .publishing {
        font-size: 14px;
        padding-top: 24px;

        .title {
            font-size: 16px;
            font-weight: bold;
        }

        .from,
        .to {
            display: flex;
            justify-content: space-between;

            span:first-child {
                font-weight: bold;
            }
        }

        .message-action {
            display: flex;
            justify-content: space-around;
            padding: 32px 0;

            &.single-button {
                justify-content: end;
            }

            button {
                color: $primary;
                text-transform: none;
                font-size: 16px;
                margin-left: 0;
            }

            .edit-message {
                color: $white;
                background-color: $primary;
            }

            .published {
                color: $red-darken-4;
            }
        }

        .loading-edit {
            background-color: transparent;

            :deep(.v-skeleton-loader__button) {
                margin: 32px 0 32px 8px;
                max-width: 120px;
            }
        }
    }
}

.loader {
    width: 350px;
    padding: 12px;
}

.confirmation-dialog {
    background-color: $white;
    padding: 16px 24px;
    border-radius: 8px;

    .title {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 24px;
        text-wrap: nowrap;
    }

    .text {
        font-size: 16px;
    }

    .actions {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        column-gap: 24px;

        .action {
            background-color: $primary;
            color: $white;

            &.published {
                background-color: $red-darken-4;
            }
        }
    }
}
</style>
