import { DefaultPageSize, Link, ListResource, Paging, Resource } from '@/shared/models';
import { defineStore } from 'pinia';
import { Message, MessageCategoryTemplate, MessageListItem, MessageScope, MessageTemplate, ServiceJourney } from './messagesModels';
import { MessagesRels } from './rels';
import { useCommonStore } from '@/shared/commonStore';
import { useAxios } from '@/shared/axios';
import { getLink } from '@/shared/LinkHelper';
import _ from 'lodash';

const emptyMessage = {
    subject: '',
    body: '',
    pushNotifications: null as boolean | null,
    channels: ['General'],
    startPublish: null,
    endPublish: null,
    receivers: {
        scopes: [],
        lines: [],
        stops: [],
        areas: [],
        companies: [],
        drivers: [],
        departures: []
    },
    structuredMessage: {
        templateId: '',
        input: {},
        choice: {}
    }
} as unknown as Message;

export const useMessagesStore = defineStore('deviation-messages', {
    state: () => ({
        messages: new Array<MessageListItem>(),
        message: _.cloneDeep(emptyMessage),
        paging: {} as Paging,
        sourceSystem: 'NotificationHub',
        query: '',
        categoryTemplates: new Array<MessageCategoryTemplate>(),
        messageTemplates: new Array<MessageTemplate>(),
        serviceJourneys: new Array<ServiceJourney>(),
        links: new Array<Link>()
    }),
    getters: {
        currentMessageTemplate: state => {
            return state.messageTemplates.find(x => state.message.structuredMessage.templateId === x.id);
        }
    },
    actions: {
        async loadMessages(page: number, id?: string, groupId?: string) {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getMessages);

            const pageSize = DefaultPageSize;
            const requestParams = new URLSearchParams();
            requestParams.append('page.number', page.toString());
            requestParams.append('page.size', pageSize.toString());
            requestParams.append('sourceSystem', this.sourceSystem);

            if (this.query) {
                requestParams.append('query', this.query);
            }

            if (id) {
                requestParams.append('id', id.toString());
            }

            if (groupId) {
                requestParams.append('groupId', groupId.toString());
            }

            const response = await axios.requestByLink<ListResource<MessageListItem>>(link, { params: requestParams });

            this.messages = response.data.data;
            this.links = response.data.links;
            this.paging = response.data.paging ?? ({} as Paging);
        },
        async loadMessage(id: string) {
            const axios = useAxios();

            let item = this.getMessageById(id);
            if (!item) {
                // fallback if message is not in the list
                await this.loadMessages(1, id);
                item = this.getMessageById(id);
                if (!item) throw Error(`Can't find message by id: ${id}`);
            }

            const link = getLink(MessagesRels.getMessage, item.links);

            const response = await axios.requestByLink<Resource<Message>>(link);

            this.message = response.data.data ?? _.cloneDeep(emptyMessage);
            this.links = response.data.links;
        },
        async initMessage() {
            await this.loadMessages(1);
            this.message = _.cloneDeep(emptyMessage);
        },
        async createMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.createMessage, this.links);

            const response = await axios.requestByLink<{ groupId: string }>(link, { data: this.message });
            const groupId = response.data.groupId;
            await this.loadMessages(1, undefined, groupId);
            return groupId;
        },
        async updateMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.updateMessage, this.links);

            await axios.requestByLink(link, { data: this.message });
        },
        async publishMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.publishMessage, this.links);

            await axios.requestByLink(link);
        },
        async unpublishMessage() {
            const axios = useAxios();
            const link = getLink(MessagesRels.unpublishMessage, this.links);

            await axios.requestByLink(link);
        },
        async publishMessageGroup(groupId: string) {
            const axios = useAxios();
            const link = getLink(MessagesRels.publishMessageGroup, this.links);

            await axios.requestByLink(link, { data: { groupId: groupId } });
        },
        async loadMessageCategories() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getMessageCategories);

            const response = await axios.requestByLink<ListResource<MessageCategoryTemplate>>(link);
            this.categoryTemplates = response.data.data;
        },
        async loadMessageTemplates() {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getMessageTemplates);

            const response = await axios.requestByLink<ListResource<MessageTemplate>>(link);
            this.messageTemplates = response.data.data;
        },
        async loadServiceJourneys({ date, linePrivateCode }: { date: string; linePrivateCode: string }) {
            const commonStore = useCommonStore();
            const axios = useAxios();
            const link = commonStore.getLink(MessagesRels.getServiceJourneys);

            const requestParams = new URLSearchParams();
            requestParams.append('date', date);
            requestParams.append('linePrivateCode', linePrivateCode);

            const response = await axios.requestByLink<ListResource<ServiceJourney>>(link, { params: requestParams });

            this.serviceJourneys = response.data.data;
        },
        getMessageById(id: string) {
            return this.messages.find(message => message.id === id);
        },
        getMessageTemplateById(id: string) {
            return this.messageTemplates.find(template => template.id === id);
        },
        getMessageTemplatesByCategory(categoryName: string) {
            return this.messageTemplates.filter(x => x.categoryName == categoryName);
        },
        getScopesFromMessageTemplates(templates: Array<MessageTemplate>) {
            return _.chain(templates).flatMap('messageScopes').uniq().value();
        },
        filterMessageTemplatesByScope(templates: Array<MessageTemplate>, scope?: MessageScope) {
            return templates.filter(x => (scope ? x.messageScopes.includes(scope) : false));
        }
    }
});
